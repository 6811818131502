.message-wrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.list-message {
  margin-right: 5px;
  height: calc(100vh - 170px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--grey-dark);
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #f0f4f8;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--grey-dark);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #333;
  }
  .title {
    color: var(--black);
    font-size: 20px;
    font-weight: 600;
  }
  .date-render {
    color: #627d98;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .list-message-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 8px;
    .list-message-item {
      cursor: pointer;
      transition: background-color 0.3s ease;
      padding: 16px 8px;
      &:hover {
        border-radius: 8px;
        background-color: var(--grey-light);
      }

      &.is-selected {
        border-radius: 8px;
        background-color: var(--grey-light);
      }
    }

    .detail-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 5px;
      overflow: hidden;
      .name {
        color: #243b53;
        font-size: 12px;
        font-weight: 600;
      }
      .time {
        color: #627d98;
        font-size: 12px;
        font-weight: 400;
      }
      .message {
        margin-bottom: 0;
        color: #334e68;
        font-size: 12px;
        font-weight: 400;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        width: 80%;
      }
      .unread-message {
        color: var(--white);
        font-size: 12px;
        font-weight: 500;
        background: #c52707;
        padding: 2px 5px;
        border-radius: 50%;
      }
    }
  }
}

.current-message {
  padding: 0 10px;
  border-left: 1px solid var(--grey-light);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 170px);
  .box-chat {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    flex-grow: 1;
    scrollbar-width: thin;
    scrollbar-color: var(--grey-dark);
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-track {
      background: #f0f4f8;
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--grey-dark);
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #333;
    }
  }

  .message {
    display: flex;
    align-items: center;
  }

  .message.received {
    justify-content: flex-start;
  }

  .message.sent {
    justify-content: flex-end;
  }

  .message-content {
    max-width: 60%;
    padding: 8px 12px;
    border-radius: 20px;
    font-size: 12px;
    word-wrap: break-word;
    margin-bottom: 0;
  }

  .message.received .message-content {
    background-color: #d9e2ec;
    color: #102a43;
    border-radius: 12px;
  }

  .message.sent .message-content {
    background-color: #2186eb;
    color: white;
    border-radius: 12px;
  }

  .avatar {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
  }

  .input-message {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    background: #f0f4f8;
    border-radius: 12px;
    width: 100%;
  }

  .input {
    border: none;
    outline: none;
    flex-grow: 1;
    padding: 10px;
    font-size: 12px;
    border-radius: 50px;
    background: #f0f4f8;
  }

  .send-button {
    color: white;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    padding: 0;
  }

  .send-button i {
    font-size: 16px;
  }
}
