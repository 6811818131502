.title {
  color: var(--black);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}
.label-field {
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  width: 100%;
  margin-bottom: 3px;
}
.input-field {
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  border: 0.5px solid var(--grey-light);
  background: var(--white);
  padding: 8px 16px;
  width: 100%;
}
.double-input {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}
.box-wrapper {
  padding: 16px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.supplier-notes {
  border-top: 4px solid #e8c242;
  background: #fcf7e5;
  .content {
    padding: 24px;
    display: flex;
    gap: 16px;
    align-items: flex-start;
    .note-text {
      p {
        color: var(--black);
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 0;
      }
    }
  }
}

.image-information {
  .introduce-image-details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
  }
  .image-preview-wrapper {
    position: relative;
    height: 100px;
    border-radius: 8px;
    overflow: hidden;
  }

  .image-preview {
    max-width: 100px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .file-input-label {
    display: inline-block;
    width: 100px;
    height: 100px;
    border: 2px dashed #ccc;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .file-input {
    display: none;
  }

  .file-icon {
    width: 50px;
    height: 50px;
  }
  .image-notes {
    display: flex;
    align-items: center;
    gap: 8px;
    p {
      color: var(--black);
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}

.submit-btn {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-top: 24px;
  .post-supplier {
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
    border-radius: 63px;
    background: var(--primary);
    padding: 8px 64px;
    border: none;
  }
}

@media screen and (max-width: 1024px) {
  .box-wrapper {
    gap: 20px;
    margin-top: 12px;
    padding: 12px;
  }
  .title {
    font-size: 20px;
  }
  .input-field {
    padding: 6px 10px;
  }
  .supplier-notes {
    .content {
      padding: 20px;
      .note-text {
        p {
          font-size: 16px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .box-wrapper {
    gap: 12px;
    margin-top: 0;
    padding: 8px;
  }
  .title {
    font-size: 16px;
  }
  .label-field {
    font-size: 14px;
  }
  .input-field {
    font-size: 14px;
    padding: 6px 8px;
  }
  .double-input {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
  .supplier-notes {
    .content {
      padding: 20px;
      .note-text {
        p {
          font-size: 14px;
        }
      }
    }
  }
}
