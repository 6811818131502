.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: white;
  padding: 50px 20px;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;

  img {
    margin-bottom: 20px;
  }
}
.button-back {
  margin-top: 10px;
  padding: 4px 16px;
  background-color: var(--white);
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
}
.button-ok {
  margin-top: 10px;
  padding: 4px 16px;
  border: none;
  background-color: var(--primary);
  color: white;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
}
.popup-message {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 50px;
}
