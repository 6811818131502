.search-wrapper {
  padding-right: 15px;
  h3 {
    margin-bottom: 32px;
    color: var(--black);
    font-size: 20px;
    font-weight: 600;
  }
  .filter-group {
    .filter-section {
      margin-bottom: 32px;
      h4 {
        color: var(--black);
        font-size: 18px;
        font-weight: 600;
      }
      .checkbox-groups {
        row-gap: 5px;
        label {
          color: var(--black);
          font-size: 16px;
          font-weight: 400;
        }
      }
      .see-more {
        color: var(--grey-black);
        font-size: 16px;
        font-weight: 400;
        text-decoration: underline !important;
        // border-bottom: 1px solid var(--grey-black);
      }
      .price-range {
        display: flex;
        align-items: center;
      }
      .separator {
        margin: 0 8px;
        font-size: 24px;
      }
    }

    .button-apply {
      border-radius: 63px;
      background: var(--primary);
      color: var(--white);
      font-size: 16px;
      font-weight: 600;
      padding: 8px 30px;
      border: none;
    }

    .button-filter {
      display: flex;
      align-items: center;
      column-gap: 4px;
      border: none;
      padding: 6px 10px;
      border-radius: 26px;
      color: var(--primary);
      font-size: 12px;
      font-weight: 400;
    }
  }
}
.mobile {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .desktop {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }

  .search-wrapper {
    position: absolute !important;
    right: 0;
    top: 0;
    display: flex;
    justify-content: right;
    padding: 0;
  }
  .filter-group {
    position: relative;
  }
  .mobile-filter {
    position: fixed;
    top: 0;
    right: 345px;
    width: 345px;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transform: translateX(100%);
    transition: transform 0.6s ease;
    z-index: 1000;
    padding: 25px;
    overflow-y: auto;
  }

  .mobile-filter.visible {
    transform: translateX(0);
  }

  .close-btn {
    cursor: pointer;
  }
}

@media screen and (max-width: 475px) {
  .search-wrapper {
    .filter-group {
      .button-filter {
        padding: 3px 10px;
      }
    }
  }
}
