@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

:root {
  --primary: #fa5a00;
  --black: #000000;
  --white: #ffffff;
  --white-light: #f3f3f3;
  --light: #eaeaea;
  --green: #03a500;
  --grey: #a9a9a9;
  --grey-black: #424242;
  --grey-dark: #929292;
  --grey-light: #d6d6d6;
}
a {
  text-decoration: none !important;
}
html {
  font-size: 62.5%;
}

body {
  font-family: 'Inter', sans-serif !important;
  font-size: 1.6rem;
  line-height: 1.2 !important;
  text-rendering: optimizespeed;
}

//Custom slick
.slick-track {
  margin: 0;
}
.slick-dots {
  position: absolute;
  bottom: 2%;
}
.slick-dots li button:before {
  font-size: 18px !important;
}

//custom ant design
.ant-input-number {
  border-radius: 32px;
  width: 140px;
}
.ant-input-number-handler-wrap {
  display: none !important;
}
.ant-input-number-input {
  border: none;
  text-align: center !important;
  padding: 0;
}
.error-message {
  margin-bottom: 0;
  margin-top: 3px;
  color: red;
  font-size: 12px;
  font-weight: 500;
}

//custom modal
.modal-dialog {
  max-width: 75% !important;
}

//Loading full screen
.fullscreen-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

canvas {
  width: 100% !important;
}
@media screen and (max-width: 992px) {
  .slick-dots li {
    margin: 0;
  }
  .slick-dots li button:before {
    font-size: 15px !important;
  }
  .modal-dialog {
    max-width: 100% !important;
  }
}
@media screen and (max-width: 475px) {
  .slick-dots li button:before {
    font-size: 12px !important;
  }
}
