.search {
  position: relative;

  .search-field {
    font-size: 12px;
    border-radius: 128px;
    padding: 12px 0 12px 55px;
    border-color: #f47621;
  }

  .search-btn {
    position: absolute;
    top: 0;
    right: 0;
    color: var(--white);
    background-color: var(--primary);
    padding: 12px 0 12px 16px;
    border: none;
    width: 54px;
    height: 100%;
    border-radius: 0 128px 128px 0;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .camera-icon {
    position: absolute;
    top: 20%;
    left: 2.5%;
    width: 30px;
    height: 24px;
  }
}
.suggest {
  font-size: 12px;
  margin-top: 8px;
}
.search-results {
  padding: 10px;
  background: var(--white);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%; /* Set the width to match the search field */
  max-width: 100%; /* Ensures it doesn’t overflow */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.search-src {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.search-result-item {
  display: flex;
  gap: 10px;
  cursor: pointer;
  align-items: center;
}

.search-result-name {
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit text to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  max-width: calc(100% - 50px); /* Account for image width and padding */
}

@media screen and (max-width: 768px) {
}
