.dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.summary-boxes {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  gap: 10px;
  .summary-box {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid var(--light);
    background: var(--white);
    text-align: center;
    flex-grow: 1;
    min-width: 100px;
    h3 {
      color: var(--black);
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0;
    }
    p {
      color: var(--black);
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}
.chart-container {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
  padding: 20px;
  h3 {
    color: #9291a5;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  p {
    color: #1e1b39;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 22px;
    font-weight: 700;
  }
}
@media screen and (max-width: 1200px) {
  .summary-boxes {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .summary-boxes {
    .summary-box {
      gap: 8px;
      h3 {
        font-size: 16px;
      }
      p {
        font-size: 12px;
      }
    }
  }
  .chart-container {
    padding: 8px;
    h3 {
      font-size: 14px;
      margin-bottom: 8px;
    }
    p {
      font-size: 16px;
    }
  }
}
