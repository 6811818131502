.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  position: relative;
  background: white;
  padding: 60px;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;

  .icon-image {
    margin-bottom: 20px;
  }
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .icon-image {
    width: 120px;
    height: 120px;
  }
  .popup {
    padding: 40px;
    font-size: 18px;
  }
}
