.fw-bold-600 {
  font-weight: 600;
}
.text-primary {
  color: var(--primary);
}

.store-header {
  .image-wrapper {
    position: relative;
    max-height: 200px;
    overflow: hidden;

    .store-banner {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
      z-index: 1;
    }

    img.store-banner {
      position: relative;
      z-index: 0;
      display: block;
    }
  }
  .store-avatar {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 50%;
  }
  .search-field-wrapper {
    position: absolute;
    top: 3%;
    width: 100%;
    z-index: 10;
    .wrapper {
      display: flex;
      justify-content: space-between;
      .back-icon {
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.4);
        padding: 3px;
      }
    }
    .search {
      position: relative;
      width: 65%;
      .search-field {
        font-size: 12px;
        border-radius: 128px;
        padding: 5px 60px 5px 12px;
        border-color: #f47621;
      }

      .search-btn {
        position: absolute;
        top: 0;
        right: 0;
        color: var(--white);
        background-color: var(--primary);
        padding: 5px 0 5px 12px;
        border: none;
        width: 45px;
        height: 100%;
        border-radius: 0 128px 128px 0;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .store-details {
    position: absolute;
    top: 14%;
    z-index: 10;
    width: 100%;
    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .store-infor {
        display: flex;
        gap: 8px;
        align-items: center;
        h3 {
          color: var(--white);
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 0;
        }
        p {
          color: var(--white);
          font-size: 12px;
          font-weight: 400;
          margin: 4px 0;
        }
        .follower {
          color: var(--white);
          font-size: 14px;
          font-weight: 400;
        }
      }
      .store-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        color: var(--primary);
        font-size: 12px;
        .follow {
          border-radius: 63px;
          border: 1px solid var(--primary);
          background: #fdf1e7;
          padding: 4px 8px;
          img {
            width: 16px;
            height: 16px;
          }
          button {
            color: var(--primary);
            font-weight: 600;
            border: none;
            background: none;
          }
        }
        .see-phone {
          border-radius: 63px;
          border: 1px solid var(--primary);
          background: var(--primary);
          padding: 4px 8px;
          img {
            width: 16px;
            height: 16px;
          }
          button {
            color: var(--white);
            font-weight: 600;
            border: none;
            background: none;
          }
        }
      }
    }
  }
  .store-strategy-wrapper {
    border-bottom: 1px solid var(--light);
    padding: 5px 0;
    .store-strategy {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .province,
      .contact,
      .response {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 5px;
      }
      .province {
        width: 35%;
        img {
          width: 16px;
          height: 16px;
          margin: 0 auto;
        }
      }
      .contact {
        width: 30%;
        border-left: 1px solid var(--light);
        border-right: 1px solid var(--light);
        .contact-static {
          color: var(--black);
          font-size: 18px;
          font-weight: 600;
        }
      }
      .response {
        width: 35%;
        .response-static {
          color: var(--green);
          font-size: 18px;
          font-weight: 600;
        }
      }
      .text {
        color: var(--grey-black);
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
