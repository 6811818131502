//All header of each title
.title-header {
  h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .see-all {
    cursor: pointer;
    p {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      color: var(--black);
      font-size: 16px;
      font-weight: 600;
      column-gap: 8px;
    }
  }
}

//Banner
.banner {
  img {
    width: 100%;
  }
  .banner-slide {
    & .slick-slider & .slick-dots & li & button:before {
      font-size: 20px !important;
    }
  }
  .banner-right {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}

//Hot Deal
.hot-deal {
  margin-top: 45px;
  .hot-deal-icon {
    margin-right: 16px;
  }
  .hot-deal-wrapper {
    margin-top: 25px;
    .heart-icon {
      position: absolute;
      top: 10px;
      right: 25px;
      cursor: pointer;
      transition: transform 0.2s ease-in-out;
      opacity: 0;
      &.active-heart {
        opacity: 1;
      }
    }

    .list-item:hover .heart-icon {
      opacity: 1;
      transform: scale(1.1);
    }
    .list-item {
      position: relative;
      margin: 8px;
      text-align: left;
      padding-right: 15px;

      .product-img {
        width: 100%;
        height: 200px;
      }

      h3 {
        margin-top: 12px;
        margin-bottom: 0;
        color: var(--black);
        font-size: 18px;
        font-weight: 600;
        span {
          color: var(--grey);
          font-size: 14px;
          font-weight: 400;
        }
      }

      h4 {
        margin: 8px 0;
        color: var(--grey-dark);
        font-size: 16px;
        font-weight: 400;
        text-decoration: line-through;
        text-decoration-line: strikethrough;
      }

      h5 {
        margin-bottom: 12px;
        color: var(--grey-black);
        font-size: 16px;
        font-weight: 400;
        span {
          color: var(--Black, #000);
          font-size: 16px;
          font-weight: 600;
        }
      }

      p {
        margin-bottom: 0;
        color: var(--grey-black);
        font-size: 12px;
        font-weight: 400;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
  }
}

//Category
.category {
  margin: 64px 0;

  .category-wrapper {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin: 10px auto 0;
    grid-gap: 20px;
    width: 100%;
  }

  .category-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #f3f3f3;
    background: var(--white);
    box-sizing: border-box;

    img {
      width: 70px;
      height: 70px;
      margin-top: 10px;
      margin-bottom: 8px;
    }

    h3 {
      color: var(--black);
      font-size: 14px;
      font-weight: 500;
    }

    p {
      color: var(--grey-dark);
      font-size: 10px;
      font-weight: 400;
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    z-index: 9999;
  }

  .sidebar {
    width: 40%;
    background-color: var(--white);
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
    position: relative;
  }

  .see-all-wrapper {
    padding: 32px 20px;
    .header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 32px;
      margin-bottom: 16px;
      border-bottom: 1px solid var(--light);
      .close {
        width: 24px;
        height: 24px;
      }
      h1 {
        color: var(--black);
        font-size: 24px;
        font-weight: 600;
      }
    }
    .cate-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin: 10px auto 0;
      grid-gap: 20px;
      width: 100%;
      max-width: calc(3 * 200px + 2 * 20px);
      .cate-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #f3f3f3;
        background: var(--white);
        box-sizing: border-box;
        img {
          width: 70px;
          height: 70px;
          margin-top: 10px;
          margin-bottom: 8px;
        }

        h3 {
          color: var(--black);
          font-size: 14px;
          font-weight: 500;
        }

        p {
          color: var(--grey-dark);
          font-size: 10px;
          font-weight: 400;
        }
      }
    }
  }
}

//Banner Advertisement
.banner-ad {
  img {
    width: 100%;
    height: auto;
    max-height: 126px;
  }
}

//Supplier
.supplier {
  margin: 64px 0;

  .supplier-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    margin-top: 24px;
  }

  .supplier-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #f3f3f3;
    background: var(--white);
    flex: 0 1 calc(33.333% - 20px);
    height: auto;
    box-sizing: border-box;

    .supplier-image {
      width: 110px;
      height: 110px;
      margin-right: 8px;
    }

    h3 {
      color: var(--black);
      font-size: 14px;
      font-weight: 600;
    }

    .find-supplier {
      display: flex;
      padding: 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      width: fit-content;
      border-radius: 39px;
      background: #f3f3f3;
      color: var(--black);
      margin: 15px 0;
      font-size: 12px;
      font-weight: 400;
    }

    .supplier-location {
      color: var(--grey);
      font-size: 14px;
      font-weight: 500;
      column-gap: 5px;
    }
  }
}

//Brand
.brand {
  .title-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .brand-icon {
    margin-right: 16px;
  }
  .brand-wrapper {
    .brand-item {
      margin: 8px;
    }
    .brand-img {
      width: 100%;
      height: auto;
      max-width: 150px;
      max-height: 150px;
    }

    :global {
      .slick-prev,
      .slick-next {
        border-radius: 50%;
        background: transparent;
        padding: 10px;
        z-index: 1;
      }

      .slick-prev:before,
      .slick-next:before {
        font-size: 20px;
        color: var(--black);
        opacity: 1;
        background: rgba(148, 148, 148, 0.5);
        padding: 10px;
        border-radius: 50%;
        line-height: 0;
      }

      .slick-prev {
        left: 0;
      }

      .slick-next {
        right: 3%;
      }
    }
  }
}

//For You
.for-you {
  margin: 60px 0;
  .for-you-wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin: 10px auto 0;
    grid-gap: 20px;
    width: 100%;
    max-width: calc(6 * 200px + 5 * 20px);

    .heart-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      transition: transform 0.2s ease-in-out;
      opacity: 0;
      &.active-heart {
        opacity: 1;
      }
    }
    .for-you-item:hover .heart-icon {
      opacity: 1;
      transform: scale(1.1);
    }
  }

  .for-you-item {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-sizing: border-box;
    .product-img {
      width: 100%;
      height: 200px;
    }
    h1 {
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      color: var(--black);
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 0;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    h3 {
      color: var(--grey);
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 0;
      span {
        color: var(--black);
        font-size: 16px;
        font-weight: 600;
      }
    }
    .negotiate {
      color: var(--primary);
      font-size: 12px;
      font-weight: 400;
      border-radius: 20px;
      background: rgba(250, 90, 0, 0.1);
      padding: 4px 8px;
      width: fit-content;
    }
    .buy-at-least {
      color: var(--black);
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 0;
    }
    .location {
      color: var(--grey-black);
      font-size: 10px;
      font-weight: 400;
    }
    .contact {
      color: var(--green);
      font-size: 10px;
      font-weight: 400;
    }
  }
}
@media screen and (max-width: 1200px) {
  .for-you {
    .for-you-wrapper {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  .category {
    .category-wrapper {
      grid-template-columns: repeat(6, 1fr);
    }
    .sidebar {
      width: 50%;
    }
  }
}
@media screen and (max-width: 1024px) {
  .supplier {
    .supplier-item {
      flex: 0 1 calc(50% - 20px);
    }
  }
  .category {
    .sidebar {
      width: 55%;
    }
  }
}
@media screen and (max-width: 992px) {
  .for-you {
    .for-you-wrapper {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  .category {
    .category-wrapper {
      grid-template-columns: repeat(5, 1fr);
    }
    .sidebar {
      width: 70%;
    }
  }
}
@media screen and (max-width: 768px) {
  .banner-right {
    display: none !important;
  }
  .hot-deal {
    margin-top: 20px;
    .hot-deal-icon {
      margin-right: 3px;
    }
    .hot-deal-wrapper {
      margin-top: 0;
      .list-item {
        margin: 5px;
        padding-right: 0;
      }
      .heart-icon {
        top: 10px;
        right: 10px;
      }
    }
  }
  .category {
    margin: 30px 0;
    .category-wrapper {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
    }
    .see-all-wrapper {
      .cate-wrapper {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .sidebar {
      width: 100%;
    }
  }
  .brand {
    .brand-icon {
      margin-right: 3px;
    }
    .brand-wrapper {
      .brand-item {
        margin: 5px;
      }
      :global {
        .slick-prev:before,
        .slick-next:before {
          font-size: 16px;
          padding: 6px;
          padding-top: 8px;
        }
        .slick-prev {
          left: -10px;
        }
      }
    }
  }

  .supplier {
    margin: 30px 0;
    .supplier-wrapper {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 10px;
      margin-top: 15px;
    }
    .supplier-item {
      flex: 0 1 calc(100%);
    }
  }
  .for-you {
    margin: 30px 0;
    .for-you-wrapper {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  }
}

@media screen and (max-width: 576px) {
  .title-header {
    h1 {
      font-size: 18px;
    }
    .see-all {
      p {
        font-size: 14px;
      }
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .hot-deal {
    .hot-deal-wrapper {
      .list-item {
        .product-img {
          height: 160px;
        }
      }
    }
  }
  .for-you {
    .for-you-item {
      .product-img {
        height: 160px;
      }
    }
  }
  .category {
    .category-wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
