.title-blog {
  text-align: center;
  color: var(--black);
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
.blog-content {
  padding: 20px;
  border: 1px solid var(--grey-light);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: auto;
}

/* Custom scrollbar styling */
.blog-content::-webkit-scrollbar {
  width: 6px; /* Adjust the width for a thinner scrollbar */
  height: 6px; /* Adjust the height for a horizontal scrollbar, if needed */
}

.blog-content::-webkit-scrollbar-track {
  background: var(--grey-light); /* Optional: Add a track background color */
  border-radius: 5px; /* Optional: Round the corners of the track */
}

.blog-content::-webkit-scrollbar-thumb {
  background-color: var(--grey-dark); /* Change this to your desired thumb color */
  border-radius: 5px; /* Round the corners of the scrollbar thumb */
}

.blog-content::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-color);
}

@media screen and (max-width: 768px) {
  .title-blog {
    font-size: 20px;
  }
  .blog-content {
    padding: 10px;
  }
}
