.product-wrapper {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 30px;
}
.product-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .product-images {
    padding-right: 25px;

    .main-image {
      width: 100%;
    }
    .sub-images {
      margin-top: 15px;
      display: flex;
      gap: 5px;
    }

    .sub-image {
      width: 17%;
      height: auto;
      cursor: pointer;
      transition: transform 0.3s ease;
    }
  }
  .product-infor {
    .product-title {
      color: var(--black);
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 12px;
    }
    .infor-detail {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .label-field {
        color: var(--black);
        font-size: 18px;
        font-weight: 400;
      }
      .input-field {
        color: var(--black);
        font-size: 18px;
        font-weight: 600;
      }
      .input-field-green {
        color: #0b8c00;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .notes {
      margin-top: 24px;
      color: var(--black);
      font-size: 20px;
      font-weight: 400;
      p {
        margin-bottom: 0;
      }
    }
  }
}
.store-details {
  border-radius: 8px;
  background: var(--white);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: fit-content;

  .store-header {
    text-align: center;
    border-radius: 8px 8px 0 0;
    background: linear-gradient(180deg, rgba(250, 90, 0, 0.4) 0%, rgba(115, 115, 115, 0) 100%);
    padding: 24px 0;

    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }

    h3 {
      color: var(--black);
      font-size: 20px;
      font-weight: 600;
    }

    p {
      color: var(--black);
      font-size: 12px;
      font-weight: 400;
    }
  }
}
.store-information {
  padding: 0 16px 10px;

  .store-number {
    margin-top: 8px;
  }

  .title {
    color: var(--grey-dark);
    font-size: 16px;
    font-weight: 400;
  }

  .number {
    color: var(--black);
    font-size: 18px;
    font-weight: 600;
  }

  .phone-btn {
    color: var(--primary);
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    padding: 8px 0;
    border-radius: 63px;
    border: 1px solid var(--primary);
    background: #fdf1e7;
    margin-top: 10px;
  }

  .follow-btn {
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    padding: 8px 0;
    border-radius: 63px;
    border: 1px solid var(--primary);
    background: var(--primary);
    margin-top: 10px;
  }
}

.modal-title {
  color: var(--black);
  font-size: 24px !important;
  font-weight: 600 !important;
}
.double-input {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-bottom: 30px;
}
.send-request-label-field {
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  width: 100%;
  margin-bottom: 3px;
}
.send-request-input-field {
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  border: 0.5px solid var(--grey-light);
  background: var(--white);
  padding: 8px 16px;
  width: 100%;
}
.modal-submit-quote {
  border-radius: 63px;
  background: var(--primary);
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  border: none;
  padding: 8px 64px;
}
@media screen and (max-width: 992px) {
  .product-wrapper,
  .product-details {
    grid-template-columns: 1fr;
  }

  .product-details {
    .product-images {
      padding: 0;
    }
    .product-infor {
      padding-top: 16px;
      .product-title {
        font-size: 18px;
      }
      .infor-detail {
        .label-field {
          font-size: 14px;
        }
        .input-field {
          font-size: 14px;
        }
        .input-field-green {
          font-size: 14px;
        }
      }
      .notes {
        margin-top: 16px;
        font-size: 14px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .modal-title {
    font-size: 18px !important;
  }
  .double-input {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    margin-bottom: 15px;
  }
  .send-request-label-field {
    font-size: 14px;
  }
  .send-request-input-field {
    font-size: 14px;
    padding: 6px 8px;
  }
}
