.box-wrapper {
  border-radius: 8px;
  border: 1px solid var(--light);
  background: var(--white);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 24px 16px;
}
.double-input {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}
.title {
  color: var(--black);
  font-size: 18px;
  font-weight: 600;
}

.avater-wrapper {
  position: relative;
  .avartar-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
  }
  .new_avatar_icon {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px;
    background-color: var(--white);
    border-radius: 50%;
  }
}

.basic-information-details {
  display: flex;
  flex-direction: column;
  gap: 40px;
  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .names {
      color: var(--black);
      font-size: 16px;
      font-weight: 400;
    }
    .input-field {
      border-radius: 8px;
      border: 0.5px solid var(--grey-light);
      background: var(--white);
      width: 65%;
      height: 20px;
      padding: 20px;
      padding-left: 16px;
      font-size: 14px;
      font-weight: 400;
      color: var(--black);
    }
    .select-field {
      height: 40px;
      padding: 0;
      padding-left: 10px;
    }
    .address-fields {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 65%;
    }
    .field-group {
      position: relative;
    }
    .field-label {
      position: absolute;
      color: var(--grey-dark);
      font-size: 12px;
      font-weight: 400;
      top: -7px;
      left: 10px;
      z-index: 1;
      background: white;
      padding: 0 3px;
    }
    .address-select-field {
      width: 100%;
      border-radius: 8px;
      border: 0.5px solid var(--grey-light);
      background: var(--white);
      height: 40px;
      padding-left: 10px;
      font-size: 14px;
      font-weight: 400;
      color: var(--black);
    }
  }
}
.save-profile {
  border-radius: 63px;
  background: var(--primary);
  color: var(--white);
  font-size: 16px;
  font-weight: 400;
  border: none;
  padding: 8px 16px;
  width: 200px;
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .double-input {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
}
