.supplier-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.supplier-item-wrapper {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .infor-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    .shop-image {
      width: 48px;
      height: 48px;
      object-fit: cover;
      border-radius: 50%;
    }
    .name-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .shop-name {
        color: var(--black);
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      .shop-address {
        color: var(--black);
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 0;
      }
    }
  }
  .unfollow-btn {
    height: fit-content;
    border-radius: 63px;
    border: 1px solid var(--primary);
    background: var(--primary);
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
    padding: 8px 16px;
    text-wrap: nowrap;
  }
}
.no-data {
  margin-top: 10px;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .supplier-wrapper {
    gap: 10px;
  }
  .supplier-item-wrapper {
    padding: 8px 0;
    .unfollow-btn {
      font-size: 12px;
    }
  }
  .no-data {
    font-size: 12px;
  }
}
