.box-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--light);
  background: var(--white);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 24px 16px;
}
.title {
  color: var(--black);
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
}
.sub-title {
  color: var(--black);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}
.list-job-wrapper {
  .list-job {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 24px;
    grid-column-gap: 30px;
    margin: 32px auto 0;
  }
  .job-item {
    border-radius: 8px;
    border: 1px solid var(--light);
    background: var(--white);
    width: 200px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    .number {
      color: var(--black);
      font-size: 20px;
      font-weight: 600;
    }
    p {
      color: var(--black);
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}
.post-product-wrapper {
  margin-top: 32px;
  .note-content {
    background: #fcf7e5;
    width: 100%;
    display: flex;
    justify-content: start;
    padding: 24px 16px;
    margin: 32px 0;
    gap: 16px;
    .note-title {
      color: var(--black);
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}

.submit-btn {
  display: flex;
  justify-content: flex-start;
  .post-product {
    color: var(--white);
    font-size: 16px;
    font-weight: 600;
    border-radius: 63px;
    background: var(--primary);
    padding: 8px 64px;
    border: none;
  }
}
.chart-container {
  margin-top: 32px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
  padding: 20px;
  h3 {
    color: #9291a5;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  p {
    color: #1e1b39;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 22px;
    font-weight: 700;
  }
}
@media screen and (max-width: 992px) {
  .list-job-wrapper {
    .list-job {
      margin: 24px auto 0;
    }
    .job-item {
      width: 160px;
      height: 100px;
    }
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 20px;
  }
  .sub-title {
    font-size: 14px;
  }
  .list-job-wrapper {
    .list-job {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 16px;
      grid-column-gap: 20px;
      margin: 16px auto 0;
    }
    .job-item {
      .number {
        font-size: 18px;
      }
      p {
        font-size: 12px;
      }
    }
  }
  .post-product-wrapper {
    margin-top: 20px;
    .note-content {
      padding: 16px 8px;
      margin: 24px 0;
      gap: 12px;
      .note-title {
        font-size: 14px;
      }
    }
  }
  .chart-container {
    margin-top: 20px;
    padding: 8px;
    h3 {
      font-size: 14px;
      margin-bottom: 8px;
    }
    p {
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 450px) {
  .title {
    font-size: 18px;
  }
  .sub-title {
    font-size: 12px;
  }
  .list-job-wrapper {
    .list-job {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 8px;
    }
    .job-item {
      width: 200px;
      height: 90px;
      gap: 8px;
      .number {
        font-size: 16px;
      }
    }
  }
  .post-product-wrapper {
    margin-top: 16px;
    .note-content {
      margin: 16px 0;
    }
  }
  .submit-btn {
    .post-product {
      font-size: 14px;
      padding: 8px 48px;
    }
  }
}
