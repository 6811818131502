.store-wrapper {
  .store-banner {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }
  .store-information {
    margin-top: 24px;
  }
}
