.product-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  .supplier-item:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  .supplier-item {
    display: flex;
    gap: 8px;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid var(--light);
    background: var(--white);
    cursor: pointer;
    .supplier-img {
      max-width: 110px;
      max-height: 110px;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
    .information {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      h5 {
        color: var(--black);
        font-size: 14px;
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .find-supplier {
        color: var(--black);
        font-size: 12px;
        font-weight: 400;
        border-radius: 39px;
        border: unset;
        background: var(--white-light);
        padding: 2px 8px;
      }
      .location {
        img {
          width: 16px;
          height: 16px;
        }
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--grey);
        font-size: 14px;
        font-weight: 500;
      }
      .quantity {
        color: #000;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .product-wrapper {
    gap: 12px;
    .supplier-item {
      .supplier-img {
        max-width: 90px;
        max-height: 90px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .product-wrapper {
    grid-template-columns: 1fr;
    gap: 8px;
    .supplier-item {
      .information {
        .location {
          font-size: 12px;
        }
      }
    }
  }
}
