.profile-management {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.side-bar {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 12px;
  .active {
    border-right: 4px solid var(--primary);
    background: var(--light);
    .title {
      color: var(--black) !important;
    }
  }
  .item-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    cursor: pointer;

    .title {
      color: var(--grey-dark);
      font-size: 16px;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 992px) {
  .profile-management {
    display: block;
    width: 100%;
  }
  .side-bar {
    display: none;
  }
}
