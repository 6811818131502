.store-management {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.side-bar {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 12px;
  .active {
    border-radius: 4px;
    border: 1px solid #eaeaea;
    background: #f3f3f3;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    .title {
      color: var(--black) !important;
    }
  }
  .item-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    cursor: pointer;

    .title {
      color: var(--grey-dark);
      font-size: 16px;
      font-weight: 500;
    }
  }
  .item-with-sub {
    align-items: flex-start;
  }
  .title-with-sub {
    margin-top: 3px;
    width: 100%;
  }
  .dropdown {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
    .dropdown-item {
      width: 100%;
      color: var(--grey-dark);
      font-size: 16px;
      font-weight: 400;
      padding-left: 35px;
      cursor: pointer;
    }
    .sub-item-active {
      border-radius: 4px;
      background: var(--light);
      color: var(--primary);
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 992px) {
  .store-management {
    display: block;
    width: 100%;
  }
  .side-bar {
    display: none;
  }
}
