.black-text {
  color: var(--black);
  font-size: 14px;
  font-weight: 500;
}
.search {
  position: relative;
  width: 35%;
  margin-left: auto;
  .search-field {
    font-size: 12px;
    border-radius: 128px;
    padding: 5px 60px 5px 12px;
    border-color: #f47621;
  }

  .search-btn {
    position: absolute;
    top: 0;
    right: 0;
    color: var(--white);
    background-color: var(--primary);
    padding: 5px 0 5px 12px;
    border: none;
    width: 45px;
    height: 100%;
    border-radius: 0 128px 128px 0;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.table-container {
  overflow-x: auto;
  width: 100%;
  .table-customer {
    margin-top: 0;
  }
}
.customer-image {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  object-fit: cover;
}
.infor-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  .name-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .customer-name {
      color: var(--black);
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .customer-address {
      color: var(--black);
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .infor-wrapper {
    .name-wrapper {
      .customer-name {
        font-size: 16px;
      }
      .customer-address {
        color: var(--black);
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 0;
      }
    }
  }
  .search {
    width: 100%;
    margin-top: 20px;
  }
}
