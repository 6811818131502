.custom-input-number {
  position: relative;
  width: 130px;
  display: flex;
  align-items: center;
  border-radius: 32px;
}
.custom-button {
  border: none;
  background: none;
  font-size: 16px;
}
.minus {
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 1;
}
.plus {
  position: absolute;
  right: 0;
  top: 5px;
  z-index: 1;
}
