.related-product-wrapper {
  margin-top: 40px;
  h3 {
    color: var(--black);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
  }
}
.product-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin: 10px auto 0;
  grid-gap: 20px;
  width: 100%;
  max-width: calc(6 * 200px + 5 * 20px);
}
.heart-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  opacity: 0;
  &.active-heart {
    opacity: 1;
  }
}
.product-item:hover .heart-icon {
  opacity: 1;
  transform: scale(1.1);
}
.product-item {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-sizing: border-box;

  .product-img {
    width: 100%;
    height: 200px;
  }
  h1 {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    color: var(--black);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  h3 {
    color: var(--grey);
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
    span {
      color: var(--black);
      font-size: 16px;
      font-weight: 600;
    }
  }
  .negotiate {
    color: var(--primary);
    font-size: 12px;
    font-weight: 400;
    border-radius: 20px;
    background: rgba(250, 90, 0, 0.1);
    padding: 4px 8px;
    width: fit-content;
  }
  .buy-at-least {
    color: var(--black);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .location {
    color: var(--grey-black);
    font-size: 10px;
    font-weight: 400;
  }
  .contact {
    color: var(--green);
    font-size: 10px;
    font-weight: 400;
  }
}
@media screen and (max-width: 1400px) {
  .product-item {
    .product-img {
      height: 170px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .product-wrapper {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 992px) {
  .product-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 768px) {
  .product-wrapper {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
}
@media screen and (max-width: 500px) {
  .product-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
}
