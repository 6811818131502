.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 25px 0;
  background-color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.no-box-shadow {
  box-shadow: none;
}
.search-form {
  position: relative;
}
.icon-wrapper {
  color: var(--black);
  font-weight: 500;
  img {
    width: 20px;
    height: 24px;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 0;
    font-size: 12px;
  }
}
.icon-link {
  color: var(--black);
  font-weight: 500;
}
.information-form {
  position: relative;
}
.sell-with {
  position: absolute;
  right: 0;
  bottom: -30px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 27px;
  border: 1px solid var(--primary);
  padding: 4px 8px;
  background: transparent;
  span {
    color: var(--primary);
    font-size: 12px;
    font-weight: 400;
  }
}
.menu-dropdown {
}
@media screen and (max-width: 768px) {
  .wrapper {
    padding: 15px 0;
  }
  .logo-link {
    img {
      width: 120px;
      height: 55px;
    }
  }
  .search-form,
  .notification,
  .shopping-cart,
  .sell-with {
    display: none !important;
  }
}
