.store-header-show {
  margin-top: 120px;
  margin-bottom: -120px;
  background: var(--white);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.store-header-hide {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;
  margin-top: 120px;
  margin-bottom: -120px;
  background: var(--white);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  opacity: 0; /* Initially hidden */
  transform: translateY(-20px); /* Initial upward shift */
  transition: opacity 0.3s ease, transform 0.3s ease; /* Add transition */
}

.store-header-hide.visible {
  opacity: 1; /* Fully visible */
  transform: translateY(0); /* Reset position */
}

.store-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}
.store-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .store-infor {
    display: flex;
    align-items: center;
    max-width: 260px;
    img {
      width: 48px;
      height: 48px;
      margin-right: 8px;
      border-radius: 50%;
    }
    .name {
      color: var(--black);
      font-size: 20px;
      font-weight: 600;
    }
    .location {
      color: var(--black);
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
  .store-followers {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-left: 40px;
    color: var(--black);
    font-size: 16px;
    font-weight: 400;
    .fw-bold-600 {
      font-weight: 600;
    }
  }
}
.store-button {
  display: flex;
  align-items: center;
  gap: 30px;
  color: var(--primary);
  font-size: 16px;
  font-weight: 600;
  .follow,
  .see-phone {
    display: flex;
    align-items: center;
    border-radius: 63px;
    border: 1px solid var(--primary);
    background: #fdf1e7;
    padding: 4px 8px;
  }
  .unfollow {
    border-radius: 63px;
    border: 1px solid var(--primary);
    background: var(--primary);
    padding: 4px 8px;
    button {
      color: var(--white);
    }
  }
  button {
    border: none;
    background: none;
  }
}
