@import url('https://fonts.googleapis.com/css2?family=Aclonica&display=swap');

.login-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  z-index: -1; /* To ensure the image stays in the background */
}
.login-wrapper {
  display: flex;
  justify-content: right;
}
.margin-top-24 {
  margin-top: 24px;
}
.login-details {
  display: flex;
  align-items: center;
  width: 40%;
  height: 100%;
  background-color: var(--white);
  height: 100vh;
  .login-details-wrapper {
    width: 80%;
    margin: 0 auto;
  }
  .title {
    font-family: 'Aclonica', sans-serif; /* Apply Aclonica font to the title */
    color: var(--primary);
    font-size: 48px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 80px;
  }
  .login-form {
    h5 {
      color: var(--black);
      font-size: 24px;
      font-weight: 600;
    }
    .form-wrapper {
      position: relative;
    }
    .form-label {
      position: absolute;
      left: 10px;
      top: -8px;
      background-color: #fff;
      color: #5e6368;
      font-size: 12px;
      font-weight: 400;
      padding: 2px;
    }
    .form-input-login {
      width: 100%;
      height: 45px;
      padding-left: 16px;
      border-radius: 4px;
      border: 1px solid #6b737a;
      color: var(--black);
      font-size: 16px;
      font-weight: 400;
    }
    .password-field {
      position: relative;
    }
    .show-password {
      position: absolute;
      right: 5px;
      top: 25%;
      cursor: pointer;
    }
    .password-note {
      color: var(--grey-dark);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
    .login-btn {
      border-radius: 63px;
      background: var(--primary);
      width: 100%;
      color: var(--white);
      font-size: 16px;
      font-weight: 600;
      border: none;
      padding: 8px 0;
      margin-top: 60px;
    }
  }
}
.error-message {
  margin-bottom: 0;
  margin-top: 3px;
  color: red;
  font-size: 12px;
  font-weight: 500;
}
@media screen and (max-width: 1200px) {
  .login-details {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .login-details {
    width: 100%;
    .title {
      font-size: 32px;
    }
    .login-form {
      h5 {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 476px) {
  .login-details {
    .login-details-wrapper {
      width: 90%;
    }
  }
}
